.table-container {
  width: 100%;
  overflow: auto;
  height: 80vh;
}

.table-container table {
  margin-bottom: 0;
}

.budget-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.budget-table input,
.budget-table input:focus {
  background-color: transparent;
  border-color: #cdcdcd;
}

.budget-table td:first-child,
.budget-table th:first-child {
  border-left: 1px solid #f0f0f0;
}

.budget-table td,
.budget-table th {
  border-right: 1px solid #f0f0f0;
  /* border-bottom: 1px solid #f0f0f0; */
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  padding: .25rem;
}

.budget-table--collapsed {
  padding-left: 0;
  padding-right: 0;
}

/* .budget-table td:not(:first-child),
.budget-table th:not(:first-child) {
  border-left: none;
} */

/* .budget-table td {
  border-top: none;
} */

/* .budget-table tr:not(:last-child) td,
.budget-table tr:not(:last-child) th {
  border-bottom: none;
} */

/* .budget-table tfoot th {
  border-top: none;
} */

.budget-table td,
.budget-table th,
.budget-table thead th {
  vertical-align: middle;
}

.budget-table thead th {
  position: sticky;
  top: 0;
  z-index: 12;
  border-top: 1px solid #f0f0f0;
  border-bottom: 2px solid #cdcdcd;
}

.budget-table thead th:nth-child(-n + 7) {
  z-index: 13;
}

.budget-table td:nth-child(-n + 7),
.budget-table th:nth-child(-n + 7),
.budget-table .subactivity-row td:nth-child(8) {
  position: sticky;
  z-index: 11;
}

.budget-table:not(.budget-table--collapsed) tr:not(.subactivity-row) th:nth-child(7),
.budget-table:not(.budget-table--collapsed) tr:not(.subactivity-row) td:nth-child(7),
.budget-table:not(.budget-table--collapsed) .subactivity-row td:nth-child(8) {
  border-right-width: 2px;
  border-right-color: #cdcdcd;
}

.budget-table td:nth-child(1),
.budget-table th:nth-child(1) {
  left: 0;
}

.budget-table td:nth-child(2),
.budget-table th:nth-child(2) {
  left: 90px; /* 1st column size */
}

.budget-table .subactivity-row td:nth-child(3) {
  left: 150px; /* 90 (1st column) + 60 (2nd column -> Cod. Sottoattività only) */
}

.budget-table td:nth-child(3),
.budget-table th:nth-child(3),
.budget-table .subactivity-row td:nth-child(4) {
  left: 196px; /* 90 (1st column) + 60 + 46 (2nd + 3rd columns size -> Cod. Sottoattività, block activity button included) */
}

.budget-table td:nth-child(4),
.budget-table th:nth-child(4),
.budget-table .subactivity-row td:nth-child(5) {
  left: calc(196px + 250px);
}

.budget-table td:nth-child(5),
.budget-table th:nth-child(5),
.budget-table .subactivity-row td:nth-child(6) {
  left: calc(196px + 250px + 110px);
}

.budget-table td:nth-child(6),
.budget-table th:nth-child(6),
.budget-table .subactivity-row td:nth-child(7) {
  left: calc(196px + 250px + 110px + 110px);
}

.budget-table td:nth-child(7),
.budget-table th:nth-child(7),
.budget-table .subactivity-row td:nth-child(8) {
  left: calc(196px + 250px + 110px + 110px + 110px);
}

.td--numeric {
  text-align: right;
}

.th--numeric {
  text-align: center;
}

.td-cost--invalid {
  color: red !important;
}

/* .budget-row--activity {
  border-top: 2px solid #cdcdcd;
  border-bottom: 2px solid #cdcdcd;
} */

.budget-row--activity th {
  /* border-top: 2px solid #cdcdcd; */
  background-color: #f4f4f4 !important;
  border-top: 2px solid #cdcdcd;
  /* border-bottom: 2px solid #cdcdcd; */
}

.budget-row--activity:first-child th {
  border-top: none;
}

.budget-table .budget-row--activity + :not(.budget-row--activity) td {
  border-top: 2px solid #cdcdcd;
}

.budget-table tfoot th {
  border-top: 2px solid #cdcdcd;
  border-bottom: 2px solid #cdcdcd;
}

/* .subactivity-row {
  font-size: 0.875rem;
} */

.subactivity-lock-cell {
  text-align: center;
}

.budget-table th,
.budget-table td,
.th-cost,
.th-revenue,
.th-residue {
  color: #666;
}

.th-cost,
.td-cost {
  background-color: #f2feef !important;
}

.th-revenue,
.td-revenue {
  background-color: #d6fdff !important;
}

.th-residue,
.td-residue {
  background-color: #faffd3 !important;
}

.th-default {
  /* background-color: #e2e8f0 !important; */
  background-color: #fff !important;
}

/* .budget-table th {
  font-size: .75rem;
} */

.budget-table,
.budget-table input,
.budget-table button {
  font-size: 0.75rem !important;
}

.budget-table input,
.budget-table button {
  line-height: inherit;
  padding: .1rem .25rem !important;
}

.budget-table input {
  font-family: Nunito;
  height: auto;
  line-height: inherit;
  color: #495057;
}

.budget-table thead th {
  text-transform: uppercase;
}

.budget-table--collapsed thead th {
  padding: 0.75rem 0.5rem;
}

.budget-table--collapsed thead th:nth-child(-n + 2) {
  width: 10%;
}

/* target the description header column */
.budget-table--collapsed thead th:nth-child(3) {
  width: 20%;
}

.budget-table--collapsed thead th:nth-last-child(-n + 4) {
  width: 15%;
  text-align: center;
}
