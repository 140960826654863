.table {
  margin-bottom: 0;
}

.table tbody tr:hover td,
.table tbody tr:hover th,
.table tbody td:hover .cell__text-truncation-wrapper {
  background: #faffd3 !important;
}

.table--bordered td,
.table--bordered th {
  border: 1px solid #f0f0f0;
}

.table tbody {
  background-color: #fff;
}

.right {
  float: right;
}

.left {
  float: left;
}

button {
  background-color: inherit;
  border: none;
}

.filtersPopup {
  position: absolute;
  background: #f8f9fc;
  border: 1px solid #dcdcdc;
  padding: 3px 0px 18px 0px;
  box-shadow: 6px 6px 11px 0px #afafaf;
  display: initial;
  z-index: 2;
}

.heading-handler {
  vertical-align: top !important;
}

.resize-handler {
  resize: horizontal;
}

.sort-btn {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: normal;
}

.arrowNull {
  color: lightgray;
}

.dateInput {
  max-width: 90%;
  align-self: flex-end;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  height: 20px;
}

.dateInputContainer {
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 0%;
}

.inputContainer {
  display: flex;
}

.table td,
.table th {
  padding: 0.25rem 0.5rem;
  line-height: 1;
  position: relative;
}

.table--sm td,
.table--sm th {
  padding: 0.25rem;
}

.table th {
  text-transform: uppercase;
}

.table--sm td button,
.table--sm .filter-input,
.table--sm .sort-btn,
.table--sm .pagination-select,
.table--sm td,
.table--sm th {
  font-size: 0.7rem !important;
  line-height: 1 !important;
}

.cell__text-truncation-wrapper {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

/* .table td:hover .cell__text-truncation-wrapper {
  position: absolute;
  top: 1px;
  left: 0;
  color: #000 !important;
  z-index: 50;
  white-space: normal;
  word-break: break-word;
  text-overflow: initial;
}

.table td:hover .cell__text-truncation-wrapper {
  padding: 0.5rem;
}

.table--sm td:hover .cell__text-truncation-wrapper {
  padding: 0.25rem;
} */

.table .head-with-filter {
  position: relative;
  padding-bottom: calc(31px + 1rem);
}

.table--sm .head-with-filter {
  padding-bottom: calc(25px + 0.5rem);
}

.table .filter-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
}

.table--sm .filter-container {
  padding: 0.25rem;
}

.table--sm .pagination-select {
  width: auto;
  padding: 0.25rem;
  border-radius: 0.2rem;
  height: 25px;
}

.table--sm .btn-pagination {
  height: 25px;
}

.table__footer th,
.table__footer td,
.table__head th,
.table__head td {
  font-weight: 600;
  background-color: #f8f9fc !important;
  border-top: 2px solid #cdcdcd !important;
  border-bottom: 2px solid #cdcdcd !important;
}

.table thead th,
.table thead td {
  position: sticky !important;
  top: 0;
  z-index: 5;
  border-left: 0 !important;
}

.table thead th:first-child,
.table thead td:first-child {
  z-index: 10;
  left: 0;
}

.table tbody th:first-child,
.table tbody td:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 10;
  border-left: 0 !important;
}

.table__footer td {
  position: sticky !important;
  border-right: 0;
  border-left: 0;
}

.btn-pagination > i {
  vertical-align: sub;
}

.table--sm .btn-pagination > i {
  vertical-align: middle;
}

.filter-input,
.pagination-select,
.btn-pagination {
  height: 31px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.table--sm .filter-input {
  padding: 0.25rem !important;
  height: 25px !important;
}

.table tbody td,
.table tbody th {
  vertical-align: middle;
}

.table :is(th, td):first-child:before,
.table :is(th, td):first-child:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 11;
  border-right: 1px solid #e3e6f0;
}

.table :is(th, td):first-child:before {
  inset: 0 auto 0 0;
}

.table :is(th, td):first-child:after {
  inset: 0 0 0 auto;
}

.table :is(thead, tbody) :is(th, td):first-child:after {
  inset: 0 0 0 auto;
  border-right-width: 2px;
}

.table--fixed {
  table-layout: fixed;
}

.w-xxs {
  width: 35px;
}

.w-sm {
  width: 75px;
}

.w-md,
.w-md-0 {
  width: 100px;
}

.w-md-1 {
  width: 110px;
}

.w-md-2 {
  width: 120px;
}

.w-lg {
  width: 150px;
}

.w-xl {
  width: 200px;
}
