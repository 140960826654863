
.autocomplete {
    display: grid;
    position: relative;
    border: 1px solid #cccccc;
    line-height: 33px;
    border-radius: 4px;
}
.autocomplete:hover {
    border-color: #9d9d9d;
}
.suggestions {
    list-style: none;
    margin: 0px;
    padding: 0px;
    position: absolute;
    z-index: 99;
    background-color: #ffffff;
    top: 42px;
    border: 1px solid #cccccc;
    max-height: 200px;
    overflow-y: auto;
}
.sugg {
    padding: 10px;
    cursor: pointer;
    line-height: 18px;
}
.hr {
    display: block;
    background: #cccccc;
    width: 1px;
    height: 17px;
    margin-top: 8px;
    margin-bottom: 1px;
}
.sugg:hover {
    background-color: #DEEBFF;
}
.input, .input:focus, .input:active {
    border: 0px;
    color: #888a88;
}
.svg {
    text-align: center;
    width: 37px;
    color: #cccccc;
    cursor: pointer;
}
.svg:hover {
    color: #9d9d9d;
}
.suggestions {
    width: 100%;
}
.nota-elem {
    margin-top: 5px;
    min-width: 350px;
}
.nota-elem > .message-badge {
    position: absolute;
    bottom: 0;
    right: 0;
}
.nota-elem:hover > .message-badge {
    background-color: orange;
}
.custom-select-header {
    display: grid;
    grid-template-columns: 1fr 25px;
    background: #fff;
    min-height: calc(1.5em + .75rem + 2px);
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    padding: .375rem .75rem;
}
.custom-select-article {
    background: #fff;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    filter: drop-shadow(5px 5px 10px #000000);
    max-height: 300px;
    overflow: auto;
}
.purchaseOrderAttachment, .purchaseOrderAttachment-noselect {
    padding: .375rem .75rem;
}
.purchaseOrderAttachment:hover {
    background-color: #1967d2;
    color: #ffffff;
    cursor: pointer;
}
.attachment-item {
    margin-top: 5px;
    padding: 5px;
    border-radius: 2.5px;
}
.attachment-item:hover {
    background-color: #1966d267;
    color: #000000;
}